// Main colors
$fonts: #fff;
$background: #141D2F;
$red: #ff4647;
$orange: #fa7902;

@mixin dFlex($jc: center, $ai: center, $fd: row, $gap: 0) {
  display: flex;
  flex-direction: $fd;
  justify-content: $jc;
  align-items: $ai;
  gap: $gap;
}

@import './fonts.scss';


* {
  font-family: 'Poppins', "Helvetica Neue", sans-serif;
}

html, body {
  height: 100%;
  scroll-behavior: smooth;
}

body {
  margin: 0 auto;
  color: $fonts;
  background-color: $background;
}

.d-none {
  display: none !important;
}

h1, h2 {
  font-family: 'Poppins', Arial, Helvetica, sans-serif;
  font-size: 80px;
  font-weight: bold;
  line-height: 108px;
  margin: 0;
}
h3 {
  font-family: 'Poppins', Arial, Helvetica, sans-serif;
  font-size: 32px;
  font-weight: bold;
  color: $red;
  margin: 0;
}

h4 {
  font-family: 'Poppins', Arial, Helvetica, sans-serif;
  font-size: 23px;
  color: $orange;
  margin: 0;
}

a {
  text-decoration: none;
  color: $fonts;
}

.positon-relative {
  position: relative;
}

.section-styles {
  width: 100%;
  max-width: 1920px;
  padding: 150px 140px;
  margin: 0 auto;
  position: relative;
  box-sizing: border-box;
  @include dFlex($jc: space-between);
}

.primary-btn {
  width: auto;
  padding: 15px 30px;
  background-color: $orange;
  color: $fonts;
  font-size: 23px;
  line-height: 27.6px;
  border-radius: 10px;
  border: none;
  box-shadow: 4px 4px 4px 0px #00000040;
  transition: 0.35s padding ease-in-out;

  &:hover {
      cursor: pointer;
      padding: 15px 50px;
      background-color: $red;

  }
}

.secondary-btn {
  width: auto;
  padding: 15px 30px;
  color: $fonts;
  font-size: 23px;
  line-height: 27.6px;
  border-radius: 10px;
  border: none;
  outline: 1px solid $orange;
  background-color: rgba(255, 255, 255, 0);

  transition: 0.35s padding ease-in-out;

  &:hover {
      cursor: pointer;
      padding: 15px 50px;
      border: none;
      background-color: $orange;

  }
}

/* width */
::-webkit-scrollbar {
  width: 12px;
}

/* Track */
::-webkit-scrollbar-track {
  background: rgb(203, 203, 203);
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: $red;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: $orange;
}

@import './responsive.scss';
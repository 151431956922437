/**************** OVERSIZE **************/
@media screen and (min-width: 1920px) {


    /********** ABOUT ME **********/
    .border {
        height: 0px !important;
    }

    /********** SKILLS **********/
    .skills-border {
        border-top-right-radius: 50px;
        border-bottom-right-radius: 50px;
    }

    /********** PORTFOLIO **********/
    .title-and-border {
        position: absolute;
        right: unset !important;

        >.border-left {
            border-radius: 50px;
            width: 100px !important;
        }

        >.border-right {
            border-radius: 50px;
            width: 100px !important;
        }
    }

    /********** CONTACT **********/
    .contact-border-left {
        border-radius: 50px;
    }
}

/**************** LAPTOP **************/
@media screen and (max-width: 1650px) {

    /********** GLOBAL **********/
    h1,
    h2 {
        font-size: 70px;
        line-height: 80px;
    }

    h3 {
        font-size: 32px;
    }

    h4 {
        font-size: 23px;
    }

    // .section-styles {
    //     padding: 0 140px;
    //     margin: 0 auto 200px auto;
    // }

    .primary-btn {
        width: auto;
        padding: 15px 25px;
        font-size: 20px;
        line-height: 27.6px;

        &:hover {
            cursor: pointer;
            padding: 15px 40px;
            background-color: $red;
        }
    }

    /********** HERO **********/
    .hero {
        height: 100vh;
        width: 100%;
        max-width: 1920px;
        margin: 0 auto;
        padding: 0 100px;
        box-sizing: border-box;
        @include dFlex();
    }

    .name {
        >.name-text {

            >.name-title h2 {
                font-size: 48px;
                line-height: 47px;
            }

            >.side-text {
                font-size: 32px;
                width: 115px;
            }
        }
    }

    .person {
        >img {
            position: absolute;
            height: 700px;
        }
    }

    .hero-shape {
        top: 55%;
    }

    /********** ABOUT ME **********/
    .kreis-foto {
        position: absolute;
        right: 0;
        @include dFlex();

        >img {
            height: 400px;
        }

        >.border {
            height: 5px;
            width: 150px;
            background-color: $orange;
        }
    }

    /********** SKILLS **********/
    .skills-container {
        display: flex;
        gap: 50px;
        flex-flow: initial;
        flex-wrap: wrap;
        max-width: 750px;

        >.skill {
            width: 120px;
            @include dFlex($fd: column, $gap: 10px);
        }
    }

    /********** PORTFOLIO **********/
    .portfolio-buttons {
        gap: 50px !important;
    }

    .border-left {
        border-radius: 50px;
    }

    .border-right {
        border-top-left-radius: 50px;
        border-bottom-left-radius: 50px;
    }
}

@media screen and (max-width: 1450px) {

    /********** GLOBAL **********/
    h1,
    h2 {
        font-size: 62px;
        line-height: 80px;
    }

    /********** HERO **********/
    .hero {
        height: 100vh;
        width: 100%;
        max-width: 1920px;
        margin: 0 auto;
        padding: 0 40px;
        box-sizing: border-box;
        @include dFlex();
    }

    .name {
        >.name-text {
            >.name-title h2 {
                font-size: 42px;
                line-height: 47px;
            }
        }
    }

    /******** */
    .portfolio-content {
        >.portfolio-left {
            margin: 0 80px;
            height: 440px;
        }

        >.portfolio-right {
            >.portfolio-buttons {
                margin-top: 30px;
                display: flex;
                gap: 30px !important;
            }
        }
    }

    /******** */
    .about-me-text {
        width: 50%;
    }
}

@media screen and (max-width: 1300px) {

    /********** GLOBAL **********/
    h1,
    h2 {
        font-size: 60px;
        line-height: 70px;
    }

    h3 {
        font-size: 32px;
    }

    h4 {
        font-size: 23px;
    }

    .primary-btn {
        width: auto;
        padding: 15px 25px;
        font-size: 18px;
        line-height: 27.6px;

        &:hover {
            cursor: pointer;
            padding: 15px 40px;
            background-color: $red;
        }
    }

    /********** HERO **********/
    .hero {
        height: 100vh;
        width: 100%;
        max-width: 1920px;
        margin: 0 auto;
        padding: 0 20px;
        box-sizing: border-box;
        @include dFlex();
    }

    .name {
        >.name-text {

            >.name-title h2 {
                font-size: 40px;
                line-height: 47px;
            }

            >.side-text {
                font-size: 26px;
            }
        }
    }

    .person {
        width: 100%;

        >img {
            position: absolute;
            height: 600px;
        }
    }

    .hero-shape {
        top: 55%;
    }

    .info {
        position: absolute;
        bottom: 20px;
        right: 90px;
        left: 0px;
        @include dFlex($jc: space-between);

        >.social {
            @include dFlex($gap: 50px);

            >.icon-list {
                @include dFlex($gap: 40px);

                >img {
                    height: 36px;
                }
            }

            >a {
                font-size: 20px;
            }
        }
    }

    .scroll-down {
        height: 120px;
    }

    /********** ABOUT ME **********/
    .kreis-foto {
        position: absolute;
        right: 0;
        @include dFlex();

        >img {
            height: 400px;
        }

        >.border {
            height: 5px;
            width: 150px;
            background-color: $orange;
        }
    }


    /********** SKILLS **********/
    .skills-container {
        display: flex;
        gap: 40px;
        flex-flow: initial;
        flex-wrap: wrap;
        max-width: 50%;

        >.skill {
            width: 100px;
            @include dFlex($fd: column, $gap: 10px);
        }
    }

    /********** PORTFOLIO **********/
    .portfolio-content {
        >.portfolio-left {
            margin: 0;
        }
        >.portfolio-right {
            width: unset;
            @include dFlex($ai: flex-start, $fd: column, $gap: 10px);

            >.portfolio-buttons {
                margin-top: 30px;
                display: flex;
                gap: 100px;
            }
        }
    }

    .portfolio-buttons {
        gap: 50px !important;
    }

    .border-left {
        border-radius: 50px;
    }

    .border-right {
        border-top-left-radius: 50px;
        border-bottom-left-radius: 50px;
    }
}

/**************** MOBILE **************/
@media screen and (max-width: 1090px) {

    /********** HEADER **********/
    header {
        padding: 20px;

        >img {
            height: 35px;
        }

        >nav {
            display: none;
        }

        >.mobile-menu {
            display: block;
            cursor: pointer;
        }

        >.mobile-nav {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            gap: 100px;
            position: fixed;
            overflow: auto;
            height: 100vh;
            width: 100%;
            z-index: 999;
            background-color: #404856;
            top: 75px;
            right: 0;
            bottom: 0;
            left: 0;
            font-size: 36px;
        }
    }

    /********** GLOBAL **********/
    h1,
    h2 {
        font-size: 40px;
        line-height: 60px;
    }

    h3 {
        font-size: 20px;
    }

    h4 {
        font-size: 23px;
    }

    .section-styles {
        max-width: unset;
        padding: 0 30px;
        margin: 0 auto 150px auto;
        z-index: 10;
    }

    .primary-btn {
        width: auto;
        padding: 15px 20px;
        font-size: 20px;
        line-height: 20px;

        &:hover {
            cursor: pointer;
            padding: 15px 40px;
            background-color: $red;
        }
    }

    /********** HERO **********/
    .hero {
        height: 100vh;
        width: 100%;
        max-width: unset;
        margin: 0 auto;
        padding: 0 20px;
        box-sizing: border-box;
        @include dFlex($fd: column, $gap: 40px);
    }


    .name {
        margin-bottom: 160px;
        gap: 25px;

        >.name-text {
            >.name-title h2 {
                font-size: 27px;
                line-height: 27px;
            }

            >.side-text {
                font-size: 20px;
                margin: -20px;
                height: auto;
                width: 80px;
            }
        }
    }

    .person {
        height: 100%;
        width: 100%;

        >img {
            position: absolute;
            width: 90%;
            margin-bottom: -100px;
        }
    }

    .hero-shape {
        top: 50%;
    }

    .info {
        position: absolute;
        bottom: 20px;
        right: 90px;
        left: 0px;
        @include dFlex($jc: space-between);

        >.social {
            @include dFlex($gap: 50px);

            >.icon-list {
                @include dFlex($gap: 30px);

                >a img {
                    height: 40px;
                }
            }

            >a {
                display: none;
            }
        }
    }

    .social-border {
        width: 65px;
    }

    .scroll-down {
        display: none;
    }

    /********** ABOUT ME **********/
    #aboute-me .section-styles {
        display: flex;
        justify-content: center;
    }

    .kreis-foto {
        display: none;

        >img {
            height: 400px;
        }

        >.border {
            height: 5px;
            width: 150px;
            background-color: $orange;
        }
    }

    .about-me-text {
        width: 100%;
    }

    .about-shape {
        position: absolute;
        height: 80%;
        right: 0;
        bottom: 20px;
        z-index: 1;
    }

    /********** SKILLS **********/
    #skills .section-styles {
        display: flex;
        flex-direction: column-reverse;
    }

    .skills-container {
        display: flex;
        justify-content: center;
        gap: 30px;
        max-width: 100%;

        >.skill {
            width: 80px;
            @include dFlex($fd: column, $gap: 10px);
        }
    }

    .my-skills-right {
        @include dFlex($ai: flex-end, $fd: column, $gap: 30px);
        padding: 0;
        margin-bottom: 80px;
        width: unset;
        text-align: right;

        >.skills-title {
            >.skills-border {
                width: 20px;
            }
        }

        >.skills-btn {
            display: none;
        }
    }

    .skills-btn-mobile {
        display: block;
        width: 100%;
        @include dFlex();
    }

    /********** PORTFOLIO **********/

    .portfolio-title {
        text-align: center;

        >.title-and-border {
            gap: 0;
            position: unset;

            >.border-left {
                position: absolute;
                left: 0;
                width: 60px;
            }

            >.border-right {
                display: none;
            }
        }

        >span {
            padding-top: 0px;
        }
    }

    .portfolio-content {
        width: 100%;
        margin-top: 120px;
        @include dFlex($fd: column, $gap: 0px);

        >.portfolio-left {
            margin: 0;
            width: 90%;
            height: 350px;
        }

        >.portfolio-right {
            width: 100%;
            text-align: center;
            @include dFlex($ai: center, $fd: column, $gap: 10px);

            >.portfolio-buttons {
                margin-top: 30px;
                display: flex;
                gap: 100px;
            }
        }
    }

    .portfolio-shape {
        height: 30%;
    }

    .portfolio-shape2 {
        width: 60%;
    }

    /********** CONTACT **********/
    #contact {
        display: flex;
        flex-direction: column;
    }

    .contact-title {
        width: 100%;
        height: unset;

        >.contact-title-and-border {
            width: 100%;
            @include dFlex($jc: center, $fd: row);
        }
    }

    .contact-border-left {
        width: 20%;
    }

    .contact-form {
        width: 100%;
        margin-top: 40px;
    }

    /********** FOOTER **********/
    .desktop-footer {
        display: none;
    }

    .mobile-footer {
        display: block;
        width: 100%;
        padding: 0 30px;
        margin: 0 auto 300px auto;
        position: relative;
        box-sizing: border-box;
        @include dFlex($jc: center, $fd: column, $gap: 40px);
        height: 400px;
        border-top: 4px solid $orange;

        >img {
            height: 50px;
        }

        >span {
            font-size: 18px;
        }

        >a {
            font-size: 20px;
        }

    }

    .go-up-btn {
        position: absolute;
        right: 50px;
        bottom: 420px;
        cursor: pointer;
    }
}

@media screen and (max-width: 400px) {

    /********** GLOBAL **********/
    h1,
    h2 {
        font-size: 30px;
        line-height: 45px;
    }

    /********** HERO **********/


    .name {
        margin-bottom: 160px;
        gap: 25px;

        >.name-text {
            >.name-title h2 {
                font-size: 20px;
                line-height: 27px;
            }

            >.side-text {
                font-size: 16px;
                margin: -20px;
                height: auto;
                width: 60px;
            }
        }
    }

    .person {
        height: 100%;
        width: 100%;

        >img {
            position: absolute;
            width: 90%;
            margin-bottom: -120px;
        }
    }

}